import { Button, Text } from "@chakra-ui/react";
import type { HeadFC } from "gatsby";
import React from "react";

const IndexPage = () => {
  return (
    <div>
      <Text>hi</Text>
      <Button>ok</Button>
    </div>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Home Page</title>;
